<template>
<div >
  <div v-for="item in wastes" v-bind:key="item.ss01DictionaryId">
        <p  style="font-weight: bold;">{{item.wasteName}}</p>
        <p>Код отхода: <span  style="font-weight: bold;">{{item.wasteCode}}</span></p>
        <p>Дата: <span  style="font-weight: bold;">{{item.data}}</span></p>
        <p>Общее количество вывозимого вида отходов: <span  style="font-weight: bold;">{{item.out_all}}</span>  {{item.agrName}}</p>
    
  <p>Передано для</p>
  <el-form
    :model="districtsList"
    ref="formedForm"
    label-position="top"
    label-width="300px"
  >
    <el-row :gutter="15">
        <el-col :span="15">
            <el-select
              v-model="item.disposal"
              placeholder="Наименование"
            >
              <el-option
                v-for="item in disposalNames"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
    </el-row>
    <el-row v-if="!('disposal' in item) && !valid"> 
      <div v-bind:style="{color:'#FF0000', fontSize: '10px'}">Заполните поле</div>
    </el-row>
  </el-form>
  <div>
  <p>Выберите контрагента</p>
  <el-form
    prop="rul"
    :model="districtsList"
    ref="formedForm"
    label-position="top"
    label-width="300px"
    :rules="rules"
  >
    <el-row :gutter="15">
        <el-col :span="15">
            <el-select
              filterable
              v-model="item.Contractors"
              placeholder="Наименование"
              :disabled="item.receivedFromPerson"
            >
              <el-option
                v-for="item in listContractors.content"
                :key="item.contractorId"
                :label="item.nameManual+'/'+(item.contractNumber==null? '' : item.contractNumber)"
                :value="item.contractorId"
              >
              </el-option>
            </el-select>
          </el-col>
    </el-row>
    <el-row :gutter="15">
      <input type="checkbox" id="checkbox" v-model="item.receivedFromPerson" @change="editCa2">
      <label for="checkbox">Реализация физическому лицу</label>
    </el-row>
    <el-row :gutter="15" v-if="item.receivedFromPerson && (new Date(item.data.split('.')[2],item.data.split('.')[1]-1,item.data.split('.')[0])>new Date(2024,3,26))">
        <el-col :span="15">
         ФИО:
        <el-input v-model="item.fio"></el-input>
      </el-col>
    </el-row>
    <el-row v-if="!('Contractors' in item)&& !('receivedFromPerson' in item ? item.receivedFromPerson: false) && !valid"> 
      <div v-bind:style="{color:'#FF0000', fontSize: '10px'}">Заполните поле контрагента либо физ лица</div>
    </el-row>
  </el-form>
  </div>
    <div v-if="item.disposal==6">
  <p>Объекты захоронения отходов</p>
  <el-form
    prop="rul"
    :model="districtsList"
    ref="formedForm"
    label-position="top"
    label-width="300px"
    :rules="rules"
  >
  <el-row :gutter="15">
        <el-col :span="15">
            <el-select 
              v-model="item.pss21"
              placeholder="Наименование"
            >
              <el-option
                v-for="item in pss21list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
    </el-row>
    <el-row v-if="!('pss21' in item)&& !('disposal' in item ? (item.disposal==6 ? false : true):(true)) && !valid"> 
      <div v-bind:style="{color:'#FF0000', fontSize: '10px'}">заполните поле объекта захоронения отхода</div>
    </el-row>
  </el-form>
  </div>
  </div>
  
  <br>
  <el-button type="primary" @click="openPasportModal"
      >Сопроводительный паспорт</el-button>
  <el-button type="primary" @click="submitForm" 
      >Сохранить и выгрузить</el-button>
  
    <custom-modal
      :visible="showModal === 'dataPasport'"
      
    >
      <disposal-pasport-form
        :action="passport" :waste="wastes"
        @addPasportToTable="addPasportToTable"
      ></disposal-pasport-form>
    </custom-modal>

</div>
</template>

<script>
import CustomModal from "@/components/customModal";
import { mapGetters } from "vuex";
import disposalPasportForm from "@/components/ecologist/disposal/disposalPasport";
import Cookies from "js-cookie";
import { AXIOS } from "@/AXIOS/http-common";
export default {
  name: "accompanyingPassport",
  components: { disposalPasportForm, CustomModal },

  data() {
    return {
      passport: {number:null,contractor1:null,contractor2:{content:0},contractor3:null,data:null,
      vehicle:null,full_name:null,tara_name:null,tara_vight:null,vehicle_vight:null,number_card:null,
      owner:null,ownerr:null,
      transfer:null,transferr:null,
    ca1:'Ручной ввод',contractor1r:null,
    ca2:'Контрагент',contractor2r:null,
    ca3:'Контрагент',contractor3r:null,
    egr_full_name:null,
    chek_null_value:false,
    fileType:".pdf",
    
    tr:'Ответственное лицо',ow:'Ответственное лицо',
    rules: {
      rul: [{required: true, message: "Заполните поле", trigger: "blur"}],
    }
  },
  valid:true,
  user_id:null,
      out_all:0,
      wastes:[],
      districtsList: null,
      disposalNames: [
        {
          id:1,
          name:"С целью использования"
        },
        {
          id:2,
          name:"С целью обезвреживания"
        },
        {
          id:3,
          name:"С целью захоронения по договору отчуждения"
        },
        {
          id:4,
          name:"С целью хранения"
        },
        {
          id:5,
          name:"Прочее"
        },
        {
          id:6,
          name:"С целью захоронения без договора отчуждения"
        },
      ],
      pss21list:[
        {
          id:211,
          name:"Полигон промышленных отходов"
        },
        {
          id:212,
          name:"Полигон токсичных промышленных отходов"
        },
        {
          id:221,
          name:"Полигон твердых коммунальных отходов "
        },
        {
          id:290,
          name:"Другое"
        },
      ],
      search: {
        disposal: null,
        dateTo: null,
        waste: null,
        wasteCode: null,
        branch: null,
        subDivision: null,
        district: null,
      },
    };    
  },

computed: {
    ...mapGetters({
      showModal: "GETSHOWMODAL",
      listContractors: "GETLISTCONTRACTORS",
      NumberPassport: "GETDATASTORED",
    }),
  },

  methods: {

    editCa2()
    {

      if (this.wastes[0].receivedFromPerson)
      {
       this.passport.contractor2r = 'Физическое лицо';
       this.passport.ca2 = 'Ручной ввод';
      }
      else 
      {
        this.passport.ca2 = 'Контрагент';
      }

    },

    addPasportToTable(item)
    {
      this.passport=item;//.action;
      //this.wastes[0]=item.wastes;
    },

    async openPasportModal() {
      await this.GetNumberPassport();
      //if (item.Contractors !== undefined)
      this.passport.contractor2 = this.wastes[0].Contractors;
      this.passport.contractor3 = this.wastes[0].Contractors;
      this.passport.data = this.wastes[0].data
      this.$store.dispatch("setShowModal", "dataPasport");
    },

    showData() {
      console.log(this.wastes);
    },

    async getAllContractors(data) {
      this.$store.dispatch("getAllContractors", data);
    },

    async submitForm()
    {
      this.validation();
      if(this.valid)
      {
        this.wastes[0].passport=this.passport
          await this.$store.dispatch("receiveDataEntryFormWasteDecline", {
          data: this.wastes
        }
        );
        this.$router.push({ path: "main"});
      }
      else
        {
          this.notification(
            "Ошибка валидации",
            "Заполните обязательные поля",
            "warning"
          );
        }
         
    },

    addPasport() {
      this.$store.dispatch("setShowModal", "disposalPasport");
    },

    async GetNumberPassport() {
     return await AXIOS.get(
      "report/get/NumberPass"
    ).then((response) => {
    if (this.passport.number==null)
    {this.passport.number = response.data.numb;
    this.passport.egr_full_name = response.data.egr_full_name;
    this.passport.contractor1r = response.data.egr_full_name;
    this.passport.contractor3r = response.data.egr_full_name;
    }
    });
  },
    validation(){
      this.wastes.forEach((waste)=>{
        if(!('disposal' in waste))
        {
          this.valid = false;
          return this.valid;
        }
        if (!('Contractors' in waste) && !('receivedFromPerson' in waste ? waste.receivedFromPerson: false))
        {
          this.valid = false;
          return this.valid;
        }
        if (!('pss21' in waste)&& !('disposal' in waste ? (waste.disposal==6 ? false : true):(true)))
        {
          this.valid = false;
          return this.valid;
        }
        this.valid = true;
        return this.valid;
      })
    }
  },
    

  async mounted() {
    this.user_id= Cookies.get('userId');
    //let dat=(new Date(this.date)).toLocaleDateString();
    this.$route.query.wastes.forEach((waste)=>{
    this.wastes.push(waste);
    })
    this.wastes.forEach(async (waste, index_waste)=>{
      var out_all=0;
      this.$set(waste,'tara_name',null);
      this.$set(waste,'tara_vight',null);
      waste.orgs.forEach(async (org)=>{  
        if ('wastorg' in org)
        if (org.wastorg[0].out!="")
        out_all=out_all+parseFloat(org.wastorg[0].out.replace(/[,]+/g, '.'));
        else org.wastorg[0].out=0;
      })
      this.$set(this.wastes[index_waste],'out_all',Math.round(out_all*100000)/100000);
      console.log("out_all: " + this.wastes[index_waste].out_all);
    })


    await this.getAllContractors({ page: 0, size: 1000 });
    console.log(this);
  },

};
</script>

<style scoped></style>
